::selection {
	background-color: $accent;
	color: #fff; }

.color-gr-accent::selection {
	-webkit-background-clip: border-box;
	-webkit-text-fill-color: currentcolor; }

html, body {
	overflow-x: hidden; }

body {
	font-family: supermolot-transforma, sans-serif;
	font-size: 18px;
	line-height: 1.67;
	color: $text; }

.container {
	padding-left: 24px;
	padding-right: 24px; }

.lock {
	overflow: hidden; }

.img-responsive {
    display: block;
    max-width: 100%;
    height: auto; }

.text-center {
	text-align: center; }

.color-gr-accent {
	background: $gr-accent;
	display: inline-block;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent; }
