.footer {
	&__inner {
		padding: 18px 0 17px 8px;
		border-top: 1px solid #d9d9d9;
		display: flex;
		align-items: center;
		justify-content: space-between; }
	&__logo {}
	&__menu {
		.menu {
			&__list {
				> li {
					> a {
						&::before {
							display: none; } } } } } }
	&__socials {} }

.socials-footer {
	display: flex;
	margin-left: -6px;
	margin-right: -6px;
	&__item {
		display: block;
		margin: 0 6px; }
	&__icon {
		width: 25px;
		display: block; } }
