.header {
	background-color: #fff;
	box-shadow: 0px 6px 25px rgba(0, 112, 215, 0.1);
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 10;
	&__container {
		display: flex;
		align-items: center;
		justify-content: space-between; }
	&__hamburger {
		display: none; }
	&__logo {
		display: block; }
	&__menu {}
	&__actions {} }

.menu {
	&__list {
		display: flex;
		list-style-type: none;
		margin: 0 -18px;
		padding: 0;
		> li {
			position: relative;
			margin: 0 18px;
			&.active, &:hover {
				> a {
					&::before {
						transform: scaleX(1); } } }
			&:hover {
				.submenu {
					opacity: 1;
					visibility: visible; } }
			&.has-submenu > a {
				&::after {
					content: '';
					display: inline-block;
					width: 10px;
					height: 10px;
					background: url(../img/icons/arrow-down-accent.svg) center / contain no-repeat;
					margin-left: 3px; } }
			> a {
				display: block;
				padding: 32px 0;
				text-transform: uppercase;
				letter-spacing: 0.25em;
				font-size: 16px;
				font-weight: bold;
				color: $text;
				text-decoration: none;
				line-height: 1;
				position: relative;
				&::before {
					content: '';
					display: block;
					position: absolute;
					left: 0;
					bottom: -4px;
					width: 100%;
					height: 4px;
					background: $gr-accent;
					transform: scaleX(0);
					transition: transform .3s ease; } } } }
	&__hamburger {} }

.submenu {
	list-style-type: none;
	margin: 0;
	padding: 26px 36px 26px 34px;
	background: #fff;
	box-shadow: 0px 6px 25px rgba(0, 112, 215, 0.1);
	position: absolute;
	top: 100%;
	left: -34px;
	width: 380px;
	opacity: 0;
	visibility: hidden;
	transition: opacity .3s ease, visibility .3s ease;
	li {}
	a {
		display: block;
		font-size: 14px;
		letter-spacing: 0.15em;
		text-transform: uppercase;
		line-height: 2.86;
		color: $text;
		&:hover {
			text-decoration: none; } } }

.actions-header {
	display: flex;
	align-items: center;
	margin-left: -22.5px;
	margin-right: -22.5px;
	&__item {
		margin: 0 22.5px; }
	&__search {}
	&__cart-btn {} }

.search {
	position: relative;
	&.active {
		.search {
			&__control {
				opacity: 1;
				visibility: visible;
				transform: translateY(-50%) scaleX(1); } } }
	&__control {
		border-radius: 20px;
		font-size: 18px;
		padding: 8px 20px;
		position: absolute;
		width: 1081px;
		right: calc(100% + 15px);
		top: 50%;
		opacity: 0;
		transform: translateY(-50%) scaleX(0);
		transform-origin: 100% 50%;
		visibility: hidden;
		transition: opacity .5s ease, visibility .5s ease, transform .5s ease; }
	&__btn {
		display: block;
		background: none;
		border: none;
		padding: 0; }
	&__icon {} }

.cart-btn {
	display: block;
	&__icon {} }
