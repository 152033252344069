// Общие компоненты

.main-title {
	font-size: 72px;
	line-height: 1; }

.section-title {
	font-size: 24px;
	line-height: 1.25;
	letter-spacing: 0.3em;
	text-transform: uppercase;
	text-align: center; }

.section-subtitle {
	font-size: 16px;
	line-height: 1;
	letter-spacing: 0.5em;
	color: $gray;
	text-transform: uppercase;
	text-align: center; }

.section-description {
	max-width: 843px;
	color: $dark;
	margin: 0 auto;
	text-align: center;
	p:last-child {
		margin-bottom: 0; } }

.btn {
	display: inline-block;
	font-size: 24px;
	font-weight: bold;
	line-height: 1.25;
	padding: 20px;
	border-radius: 15px;
	box-shadow: 0px 6px 25px rgba(0, 112, 215, 0.1);
	text-align: center;
	border: none;
	cursor: pointer;
	&:hover {
		text-decoration: none; }
	&:disabled {
		cursor: default; }
	&-accent {
		color: #fff;
		background: $gr-accent;
		&:hover {
			color: #fff; } }
	&-white {
		filter: drop-shadow(0px 4px 4px rgba(0, 112, 215, 0.1));
		background: #fff;
		color: $secondary;
		&:disabled {
			color: $gray; } } }

.slider {
	position: relative;
	&__inner {}
	&__items {}
	&__slide {}
	&__nav {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		z-index: 2;
		cursor: pointer;
		&::before {
			content: '';
			display: block;
			position: absolute;
			width: 60px;
			height: 60px;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%); }
		svg {
			width: 23px;
			height: 45px;
			fill: $secondary;
			transition: fill .3s ease; }
		&_prev {
			left: 0; }
		&_next {
			right: 0; } } }

.page-headline {
	margin: 51px 0 44px;
	&_center {
		text-align: center;
		.page-headline {
			&__title {
				justify-content: center; } } }
	&__inner {}
	&__title {
		display: flex;
		align-items: center;
		word-break: break-word; }
	&__icon {
		max-width: 125px;
		margin-right: 69px; } }

.pagination {
	font-size: 18px;
	&__list {
		display: flex;
		align-items: center;
		justify-content: center;
		list-style-type: none;
		margin: 0;
		padding: 0; }
	&__nav {
		margin: -2px 34px 0 34px;
		&.disabled {
			a {
				cursor: default; }
			svg {
				fill: #e5e5e5; } }
		a {
			display: block;
			position: relative;
			&::before {
				content: '';
				display: block;
				width: 20px;
				height: 20px;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%); } }
		svg {
			display: block;
			width: 7px;
			height: 11px;
			fill: $text; } }
	&__item {
		line-height: 1.11;
		margin: 0 10px;
		&.active {
			.pagination {
				&__link {
					font-weight: normal;
					color: $text; } } } }
	&__link {
		display: block;
		font-weight: bold;
		color: $secondary;
		transition: color .3s ease;
		&:hover {
			text-decoration: none; } } }

.card {
	background: #fff;
	box-shadow: 0px 6px 25px rgba(0, 112, 215, 0.1);
	border-radius: 15px; }

.separator {
	display: flex;
	align-items: center;
	font-size: 48px;
	line-height: 0.75;
	text-align: center;
	position: relative;
	&::before, &::after {
		content: '';
		display: block;
		height: 1px;
		background: #e5e5e5;
		flex: 1; }
	&::before {
		margin-right: 16px; }
	&::after {
		margin-left: 16px; } }

.control, .textarea {
	display: block;
	border: 1px solid #e5e5e5;
	border-radius: 15px;
	font-size: 24px;
	line-height: 1.25;
	padding: 20px 29px;
	width: 100%;
	&::placeholder {
		color: $dark; } }

.textarea {
	height: 182px;
	resize: none;
	border-radius: 0; }

.checkbox {
	display: flex;
	position: relative;
	cursor: pointer;
	&__input {
		position: absolute;
		top: 0;
		left: 0;
		width: 0;
		height: 0;
		opacity: 0;
		&:checked ~ .checkbox__box::before {
			opacity: 1; } }
	&__box {
		display: block;
		flex: 0 0 21px;
		width: 21px;
		height: 21px;
		border-radius: 5px;
		border: 1px solid $gray;
		position: relative;
		margin: 4px 15px 0 0;
		&::before {
			content: '';
			display: block;
			width: 11px;
			height: 14px;
			background: url(../img/icons/check.svg) center / contain no-repeat;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			opacity: 0;
			transition: opacity .3s ease; } }
	&__text {
		font-size: 21px;
		font-weight: bold;
		line-height: 1.43;
		color: $gray; } }

.list {
	list-style-type: none;
	margin: 0;
	padding: 0;
	li {
		position: relative;
		&::before {
			content: '';
			display: block;
			position: absolute; } }
	&-default {
		margin: 0 0 1rem 0;
		padding: 0 0 0 12px;
		li {
			padding-left: 15px;
			&::before {
				width: 2px;
				height: 2px;
				border-radius: 10px;
				background-color: $text;
				top: 13px;
				left: 0; } } }
	&-accent {
		li {
			font-size: 21px;
			font-weight: bold;
			line-height: 1.43;
			padding-left: 41px;
			position: relative;
			margin-bottom: 10px;
			&::before {
				width: 11px;
				height: 11px;
				border-radius: 50%;
				background: $gr-accent;
				top: 10px;
				left: 0; } } } }

.hamburger {
	background: none;
	border: none;
	padding: 0;
	&__line {
		width: 27px;
		height: 4px;
		background: $gr-accent;
		display: block;
		transition: all 0.3s ease-in-out;
		&:not(:last-child) {
			margin-bottom: 3px; } }
	&.is-active {
		.hamburger {
			&__line {
				&:nth-child(1) {
					transform: translateY(7px) rotate(45deg); }
				&:nth-child(2) {
					opacity: 0; }
				&:nth-child(3) {
					transform: translateY(-7px) rotate(-45deg); } } } } }

// Секции
// Секция Hero
.section-showcase {
	padding: 76px 0 89px;
	.showcase {
		margin-bottom: 75px; } }

.showcase {
	text-align: center;
	&__title {
		margin-bottom: 14px; } }

// Секция Популярные материалы
.section-popular-articles {
	padding: 0 0 109px;
	.section-subtitle {
		margin-bottom: 38px; } }

.articles-cards {
	&_row {
		.articles-cards {
			&__row {
				margin-bottom: -80px; }
			&__col {
				margin-bottom: 80px; } } }
	&__row {
		margin-left: -10px;
		margin-right: -10px;
		margin-bottom: -45px; }
	&__col {
		padding: 0 10px;
		margin-bottom: 45px; }
	&__card {}
	&__pagination {
		margin-top: 112px; } }

.card-article {
	display: block;
	color: $text;
	&:hover {
		color: $text;
		text-decoration: none; }
	&__img-wrap {
		display: flex;
		overflow: hidden;
		position: relative;
		padding-bottom: calc(100% / (397 / 255));
		margin-bottom: 27px; }
	&__img {
		object-fit: cover;
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		transition: transform 1s ease; }
	&__body {
		padding-right: 20px; }
	&__meta {
		line-height: 1;
		margin-bottom: 10px; }
	&__category {
		text-transform: uppercase;
		letter-spacing: 0.5em;
		font-size: 14px;
		margin-right: 23px; }
	&__date {
		font-size: 14px;
		color: $gray;
		letter-spacing: 0.1em; }
	&__content {}
	&__title {
		font-size: 30px;
		font-weight: bold;
		line-height: 1.16; }
	&__text-wrap {
		margin-top: 10px;
		p:last-child {
			margin-bottom: 0; } } }

.newsletter-btn {
	width: 100%; }

// Секция Партнёры
.section-partners {
	.newsletter-btn {
		margin-bottom: 120px; }
	.section-subtitle {
		margin-bottom: 50px; } }

.slider-partners {
	&__inner {
		max-width: 75%; }
	&__items {}
	&__slide {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		height: auto; }
	&__item {}
	&__nav {
		&_prev {}
		&_next {} } }

.item-partner {
	&__img {} }

// Секция Гарантия конфиденциальности
.section-privacy {
	padding: 89px 0 82px; }

.privacy {
	text-align: center;
	&__title {
		margin-bottom: 15px;
		word-wrap: break-word; }
	&__description {
		font-size: 18px; } }

// Секция Статьи
.section-articles {
	padding: 0 0 50px;
	.categories {
		margin-bottom: 100px; } }

.categories {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	text-align: center;
	&__link {
		color: $text;
		position: relative;
		padding-bottom: 4px;
		margin: 0 11px;
		&::before {
			content: '';
			display: block;
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			height: 4px;
			background: $gr-accent;
			transform: scaleX(0);
			transition: transform .3s ease; }
		&:hover {
			color: $text;
			text-decoration: none; }
		&.active {
			&::before {
				transform: scaleX(1); } } } }

// Секция Услуги
.section-services {
	&__inner {
		padding: 100px 0 0;
		border-top: 1px solid #e5e5e5; } }

.services {
	&__items {
		margin-left: -10px;
		margin-right: -10px;
		margin-bottom: -35px; }
	&__col {
		padding: 0 10px;
		height: auto;
		margin-bottom: 35px; }
	&__card {
		height: 100%; } }

.card-service {
	display: block;
	color: $secondary;
	position: relative;
	&:hover {
		text-decoration: none;
		.card-service {
			&__back {
				opacity: 1;
				visibility: visible; }
			&__title {
				color: $secondary; } } }
	&__front, &__back {
		display: flex;
		flex-direction: column;
		height: 100%;
		background: #fff;
		box-shadow: 0px 6px 25px rgba(0, 112, 215, 0.1);
		border-radius: 15px;
		overflow: hidden;
		padding: 24px 32px; }
	&__front {}
	&__back {
		background-color: $secondary;
		color: #fff;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: 0;
		visibility: hidden;
		transition: opacity .3s ease, visibility .3s ease;
		z-index: 3; }
	&__icon {
		max-width: 56px;
		margin-bottom: 40px; }
	&__title {
		font-size: 24px;
		font-weight: bold;
		line-height: 1.16;
		padding-right: 10px;
		margin-top: auto; }
	&__arrow {
		width: 7px;
		height: 11px;
		position: absolute;
		right: 30px;
		bottom: 30px;
		z-index: 2; }
	&__text-wrap {
		font-size: 16px;
		line-height: 1.3;
		p:last-child {
			margin-bottom: 0; } } }

// Секция О нас
.section-about {}

.about {
	&__row {
		margin-left: -32.5px;
		margin-right: -32.5px; }
	&__col {
		padding: 0 32.5px;
		&_content {
			flex: 0 0 65%;
			max-width: 65%; }
		&_message {
			flex: 0 0 35%;
			max-width: 35%; } }
	&__content {
		font-size: 28px;
		font-weight: 300;
		line-height: 1.57;
		p:last-child {
			margin-bottom: 0; } }
	&__message {} }

.message-about {
	padding: 34px 35px 30px 42px;
	&__title {
		font-size: 36px;
		line-height: 1.11;
		margin-bottom: 18px; }
	&__text-wrap {
		font-size: 21px;
		line-height: 1.43;
		p:last-child {
			margin-bottom: 0; } } }

// Секция Контент
.section-content {
	&__inner {
		padding: 42px 0 38px;
		border-top: 1px solid #e5e5e5; } }

.content {
	&__row {
		margin-left: -10px;
		margin-right: -10px; }
	&__col {
		padding: 0 10px;
		&_body {
			flex: 0 0 65%;
			max-width: 65%; }
		&_aside {
			flex: 0 0 35%;
			max-width: 35%; } }
	&__body {
		h3 {
			font-size: 24px; }
		p {
			margin-bottom: 30px; } }
	&__section {
		&:not(:last-child) {
			margin-bottom: 50px; } }
	&__aside {}
	&__message {} }

.message-content {
	padding: 34px 43px 38px;
	&__title {
		font-size: 36px;
		line-height: 1.11;
		margin-bottom: 30px; }
	&__list {}
	&__separator {
		margin: 22px 0; }
	&__subtitle {
		font-size: 24px;
		line-height: 1.25;
		color: $gray;
		margin-bottom: 30px; }
	&__checkboxes {
		margin-bottom: 72px; }
	&__checkbox {}
	&__btn {
		width: 100%; } }

// Корзина
.cart {
	padding: 0 0 219px;
	&__orders {
		margin-bottom: 80px; }
	&__form {} }

.orders {
	padding: 32px 52px 40px 39px;
	&__items {}
	&__item {
		&:first-child {
			padding: 0 3px 25px; } }
	&__custom {} }

.order {
	border-bottom: 1px solid #e5e5e5;
	padding: 45px 0;
	&.active {
		.order {
			&__header {
				display: none; }
			&__form {
				display: block; } } }
	&__header {
		display: flex;
		align-items: center;
		justify-content: space-between; }
	&__title {
		font-size: 24px;
		font-weight: bold;
		line-height: 1.16;
		color: $secondary;
		flex: 1 1 auto;
		margin-right: 64px; }
	&__btns {
		display: flex;
		align-items: center;
		margin-left: -14.5px;
		margin-right: -14.5px; }
	&__btn {
		background: none;
		border: none;
		padding: 0;
		position: relative;
		margin: 0 14.5px;
		&::before {
			content: '';
			display: block;
			width: 40px;
			height: 40px;
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%); }
		svg {
			display: block;
			width: 20px;
			height: 20px;
			transition: stroke .3s ease; }
		&_delete {
			&:hover svg {
				stroke: lighten($danger, 10); }
			svg {
				stroke: $danger;
				stroke-width: 3px; } }
		&_edit {
			&:hover svg {
				stroke: lighten($accent, 10); }
			svg {
				width: 18px;
				height: 15px;
				stroke: $accent;
				stroke-width: 3px; } } }
	&__form {
		display: none;
		margin-bottom: 35px; } }

.custom-order {
	&.active {
		.custom-order {
			&__form {
				display: block; }
			&__btn {
				svg {
					transform: none;
					stroke: $gray; } } } }
	&__header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 45px 0 0; }
	&__title {
		font-size: 24px;
		line-height: 1.16;
		color: $dark;
		flex: 1 1 auto;
		margin-right: 64px; }
	&__btn {
		background: none;
		border: none;
		padding: 0;
		position: relative;
		&::before {
			content: '';
			display: block;
			width: 40px;
			height: 40px;
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%); }
		svg {
			display: block;
			width: 20px;
			height: 20px;
			stroke: $accent;
			stroke-width: 3px;
			transform: rotate(-45deg);
			-webkit-transform-origin: 50%  51%;
			transition: transform .3s ease, stroke .3s ease; }
		&:hover svg {
			stroke: lighten($accent, 10); } }
	&__form {
		display: none;
		margin-top: 43px; } }

.form-order {
	&__controls {
		margin-bottom: 58px; }
	&__group {
		margin-bottom: 30px; }
	&__textarea {}
	&__btn-wrap {
		display: flex;
		justify-content: center;
		margin-left: -15px;
		margin-right: -15px; }
	&__btn {
		flex: 0 1 310px;
		max-width: 310px;
		margin: 0 15px;
		&_cancel {}
		&_save {}
		&_add {} } }

.form-cart {
	max-width: 617px;
	margin: 0 auto;
	&__title-wrap {}
	&__title {
		font-size: 36px;
		line-height: 1;
		margin-bottom: 40px; }
	&__content {}
	&__controls {}
	&__group {
		margin-bottom: 24px; }
	&__control {}
	&__text {
		font-size: 16px;
		line-height: 1.88;
		text-align: center;
		color: $dark;
		margin-bottom: 63px; }
	&__btn-wrap {
		text-align: center; }
	&__btn {
		padding: 20px 60px; } }

.page {
	.section-partners {
		padding: 120px 0; } }

// Секция Контакты
.section-contacts {
	padding: 36px 0 40px; }

.contacts {
	&__item {
		&:not(:last-child) {
			margin-bottom: 62px; } } }

.item-contact {
	text-align: center;
	&_phone {
		.item-contact {
			&__value {
				font-size: 48px;
				line-height: 0.83; } } }
	&_email {
		.item-contact {
			&__title {
				margin-bottom: 18px; } } }
	&__title {
		font-size: 28px;
		line-height: 1.57;
		margin-bottom: 30px; }
	&__value {
		font-size: 36px;
		font-weight: bold;
		line-height: 1.11;
		a {
			color: $text; } } }

// Секция Участники
.section-participants {
	padding: 0 0 137px; }

.participants {
	&__items {}
	&__item {
		&:not(:last-child) {
			margin-bottom: 30px; } }
	&__pagination {
		margin-top: 100px; } }

.item-participant {
	padding: 62px 175px 80px 0;
	&__row {
		display: flex;
		flex-wrap: wrap;
		margin-left: -5px;
		margin-right: -5px; }
	&__col {
		padding: 0 5px;
		&_img {
			flex: 0 0 42%;
			max-width: 42%; }
		&_content {
			flex: 0 0 58%;
			max-width: 58%; } }
	&__img-wrap {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%; }
	&__img {}
	&__content {}
	&__title {
		font-size: 36px;
		font-weight: bold;
		line-height: 1.11;
		margin-bottom: 8px; }
	&__text-wrap {
		font-size: 24px;
		line-height: 1.25;
		p:last-child {
			margin-bottom: 0; } }
	&__link {
		font-size: 24px;
		font-weight: bold;
		color: #1877f2;
		transition: color .3s ease;
		&:hover {
			color: lighten(#1877f2, 10); } } }

.slide-ban {
	max-width: 1960px;
	margin: 0 auto; }

.slide-ban__item {
	position: relative;
	img {
		width: 100%;
		height: 100%;
		opacity: 0; } }

.slide-ban__wrap {
	max-width: 1280px;
	width: 100%;
	padding: 0 30px;
	margin: 0 auto;
	height: 100%;
	position: relative;
	display: flex;

	flex-direction: column;
	z-index: 1;
	@media (max-width: 1345px) {
		padding: 0 45px; }
	@media (max-width: 720px) {
		padding: 0 30px; } }

.slide-ban__subtitle {
	font-size: 24px;
	font-weight: bold;
	line-height: 1.16;
	color: #0070D7;
	margin-top: 50px;
	@media (max-width: 720px) {
		font-size: 20px; } }

.slide-ban__title {
	font-size: 36px;
	max-width: 760px;
	margin: 0 auto;
	font-size: 43px;
	font-weight: 600;
	line-height: 1.12;
	color: #fff;
	text-align: center;
	margin-top: auto;
	margin-bottom: auto;
	@media (max-width: 720px) {
		font-size: 32px; } }

.slide-ban__btn {
	margin-bottom: 15px;
	position: relative;
	text-align: center;
	z-index: 2;
	width: 100%;
	margin-top: auto;
	margin-bottom: auto;
	@media (max-width: 720px) {
		margin-bottom: 30px; }
	a {
		display: inline-block;
		padding: 14px 60px;
		font-size: 18px;
		font-weight: bold;
		color: #fff;
		line-height: 1.33;
		background: linear-gradient(90deg, #0070D7 0%, #52AA56 100%);
		border-radius: 15px;
		z-index: 2;
		position: relative;

		&:hover {
			text-decoration: none; }
		@media (max-width: 720px) {
			padding: 10px 30px;
			font-size: 16px; } } }

.slide-ban--wrap {
	position: relative; }

.slide-ban__nav--arrow {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	z-index: 2;
	cursor: pointer;
	z-index: 100;
	@media (max-width: 720px) {
		display: none; } }
.slide-ban__nav--arrow::before {
	content: '';
	display: block;
	position: absolute;
	width: 60px;
	height: 60px;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%); }

.slide-ban__nav--arrow svg {
	width: 23px;
	height: 45px;
	fill: #0070D7;
	transition: fill .3s ease; }

.slide-ban-swiper {
	bottom: -30px !important;
	@media (max-width: 720px) {
		bottom: 4px !important; } }

.slide-ban__link {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1; }


.content__section--link {
	padding: 15px;
	background-color: rgba(156,191,38,.07);
	.large-text {
		font-size: 24px;
		font-weight: 700;
		line-height: 1.25;
		color: #000; }


	a {
		font-size: 24px;
		font-weight: 700;
		color: #1877f2;
		transition: color .3s ease; } }


.message-content__slide {
	&--title {
		font-size: 36px;
		max-width: 760px;
		margin: 0 auto;
		line-height: 1.11;
		margin-bottom: 30px;

		background: linear-gradient(90deg, #0070D7 0%, #52AA56 100%);
		display: inline-block;
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		&-wrap {
			text-align: center; } }
	&__slide {
		overflow: hidden;
		margin-bottom: 15px; }
	&__slide {
		&__item {
			img {
				width: 100%;
				margin-bottom: 10px; }
			&__title {
				font-size: 21px;
				font-weight: bold;
				line-height: 1.43;
				margin-bottom: 10px; }
			&__text {
				font-size: 14px;
				line-height: 1.2; } } } }

.message__current-pagination {
	text-align: center; }



.content__sidebar {
	&_title {
		font-size: 36px;
		line-height: 1.11;
		font-weight: 700;
		margin-bottom: 31px;
		background: radial-gradient(81.56% 2360.84% at 9.66% 50%, #0070D7 0%, #52AA56 94.57%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		background-clip: text;
		text-fill-color: transparent; }
	img {
		max-width: 100%; }
	&_block1 {
		margin-bottom: 60px;
		&__item {
			margin-bottom: 30px; }
		&__text {
			&__tittle {
				font-size: 24px;
				line-height: 1.25;
				color: #000000;
				font-weight: 700; }
			&__p {
				font-size: 20px;
				line-height: 1;
				color: #000000; } } }
	&_block2 {
		margin-bottom: 90px;
		&__item {
			display: flex;
			justify-content: space-between;
			margin-bottom: 24px;
			&_img {
				width: 201px;
				margin-right: 24px; }
			&_text {
				width: calc(100% - 225px);
				&--tittle {
					font-size: 21px;
					font-weight: 700;
					line-height: 1;
					color: #000000; } } } }
	&_block3 {
		margin-bottom: 90px;
		&_item {
			img {
				margin-bottom: 25px; }
			&_tittle {
				font-size: 24px;
				line-height: 1.25;
				color: #000000;
				font-weight: 700;
				margin-bottom: 15px; }
			&_p {
				font-size: 20px;
				line-height: 1;
				color: #000000;
				margin-bottom: 30px; } } }
	&_block4 {
		display: flex;
		flex-wrap: wrap;
		&_item {
			display: block;
			padding: 10px 20px;
			background: #FFFFFF;
			box-shadow: 0px 1px 10px rgba(0, 112, 215, 0.1);
			border-radius: 15px;
			font-size: 16px;
			line-height: 1;

			color: #000000;
			font-weight: 700;
			margin-right: 10px;
			margin-bottom: 10px;
			&:hover {
				text-decoration: none;
				color: #000; } } } }
