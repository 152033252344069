/*==========  Desktop First  ==========*/

@media screen and (max-width: 1200px) {
	// Общие стили и компоненты
	.main-title {
		font-size: 60px; }

	.control, .textarea {
		font-size: 22px; }
	// Шапка
	.header {
		&__logo {
			img {
				max-width: 200px; } }
		&__actions {
			display: none; } }

	.menu {
		&__list {
			margin: 0 -10px;
			> li {
				margin: 0 10px; } } }
	// Футер
	.footer {
		&__inner {
			padding: 25px 0 30px; }
		&__menu {
			display: none; }
		&__logo {
			img {
				max-width: 200px; } } }

	.socials-footer {
		margin-left: -11px;
		margin-right: -11px;
		&__item {
			margin: 0 11px; } }

	// Секции
	.section-showcase {
		padding: 60px 0 70px; }

	.section-articles {
		.categories {
			margin-bottom: 80px; } }

	.articles-cards {
		&__pagination {
			margin-top: 87px; }
		&_row {
			.articles-cards {
				&__row {
					margin-bottom: -60px; }
				&__col {
					margin-bottom: 60px; } } } }

	.card-article {
		&__title {
			font-size: 28px; }
		&__body {
			padding-right: 0; }
		&__img-wrap {
			margin-bottom: 20px; }
		&__meta {
			margin-bottom: 15px; } }

	.section-content {
		&__inner {
			padding: 42px 0 90px; } }

	.content {
		&__col {
			&_body {
				flex: 0 0 100%;
				max-width: 100%; }
			&_aside {
				flex: 0 0 100%;
				max-width: 100%;
				margin-top: 40px; } } }

	.order {
		&__title {
			font-size: 22px; } }

	.custom-order {
		&__title {
			font-size: 22px; } }

	.form-order {
		&__textarea {
			font-size: 22px; }
		&__controls {
			margin-bottom: 47px; }
		&__btn {
			font-size: 22px; } }

	.about {
		&__row {
			margin-left: -15px;
			margin-right: -15px; }
		&__col {
			padding: 0 15px;
			&_content {
				flex: 0 0 60%;
				max-width: 60%; }
			&_message {
				flex: 0 0 40%;
				max-width: 40%; } }
		&__content {
			font-size: 24px; } }

	.section-partners {
		.newsletter-btn {
			margin-bottom: 100px; } }

	.page {
		.section-partners {
			padding: 100px 0; } }

	.cart {
		padding: 0 0 180px; }

	.item-contact {
		&_phone {
			.item-contact {
				&__value {
					font-size: 42px; } } }
		&__value {
			font-size: 32px; } }

	.participants {
		&__pagination {
			margin-top: 87px; } }

	.item-participant {
		padding: 60px 60px 60px 0; } }

@media screen and (max-width: 992px) {
	// Общие стили и компоненты
	body {
		font-size: 17px; }

	.main-title {
		font-size: 50px; }

	.page-headline {
		margin: 30px 0 44px;
		&__icon {
			max-width: 100px;
			margin-right: 50px; } }

	.control, .textarea {
		font-size: 20px; }
	// Шапка
	.header {
		padding: 28px 0;
		box-shadow: none;
		transition: box-shadow .3s ease;
		&.fixed {
			box-shadow: 0px 6px 25px rgba(0, 112, 215, 0.1); }
		&__hamburger {
			display: block; }
		&__menu {
			position: fixed;
			z-index: 10;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			overflow-y: auto;
			overflow-x: hidden;
			background-color: #fff;
			box-shadow: 0px 6px 25px rgba(0, 112, 215, 0.1);
			max-width: 317px;
			padding: 36px 24px 24px;
			transform: translate3d(-100%, 0, 0);
			visibility: hidden;
			transition: transform .7s ease, visibility .7s ease;
			&.active {
				transform: translate3d(0, 0, 0);
				visibility: visible; } }
		.menu {
			&__list {
				display: block;
				> li {
					&:not(:last-child) {
						border-bottom: 1px solid #d9d9d9; }
					> a {
						padding: 17px 11px 16px 0;
						letter-spacing: normal;
						&.active {
							&::after {
								transform: translateY(-50%) rotate(90deg); } }
						&::before {
							display: none; }
						&::after {
							content: '';
							display: block;
							width: 7px;
							height: 11px;
							background: url(../img/icons/arrow-right.svg) center / contain no-repeat;
							position: absolute;
							top: 50%;
							right: 0;
							transform: translateY(-50%);
							transition: transform .3s ease; }
						> span {
							background: $gr-accent;
							-webkit-background-clip: text;
							-webkit-text-fill-color: transparent; } } } } } }

	.menu {
		&__hamburger {
			margin-bottom: 74px; } }

	.submenu {
		position: static;
		opacity: 1;
		visibility: visible;
		display: none;
		width: 100%;
		box-shadow: none;
		padding: 5px 0 28px;
		&.active {
			display: block; }
		a {
			font-size: 16px;
			text-transform: none;
			letter-spacing: normal;
			line-height: 3; } }

	// Футер
	// Секции
	.section-showcase {
		padding: 30px 0 70px;
		.showcase {
			margin-bottom: 60px; } }

	.services {
		&__items {
			flex-wrap: nowrap;
			margin: 0; }
		&__col {
			padding: 0;
			margin-bottom: 0; } }

	.section-popular-articles {
		padding: 0 0 90px; }

	.section-articles {
		.categories {
			margin-bottom: 60px; } }

	.articles-cards {
		&_row {
			.articles-cards {
				&__row {
					margin-bottom: -47px; }
				&__col {
					margin-bottom: 47px; } } } }

	.card-article {
		&__meta {
			margin-bottom: 20px; } }

	.content {
		&__section {
			&:not(:last-child) {
				margin-bottom: 0; } } }

	.order {
		padding: 40px 0;
		&__title {
			font-size: 20px; } }

	.custom-order {
		&__header {
			padding: 40px 0 0; }
		&__title {
			font-size: 20px; }
		&__form {
			margin-top: 35px; } }

	.form-order {
		&__textarea {
			font-size: 20px; }
		&__btn {
			font-size: 20px; } }

	.about {
		&__col {
			&_content {
				flex: 0 0 100%;
				max-width: none;
				margin-bottom: 30px; }
			&_message {
				flex: 0 0 100%;
				max-width: none; } }
		&__content {
			font-size: 22px; } }

	.message-about {
		padding: 22px 30px 40px 24px; }

	.cart {
		padding: 0 0 160px; }

	.contacts {
		&__item {
			&:not(:last-child) {
				margin-bottom: 50px; } } }

	.item-contact {
		&_phone {
			.item-contact {
				&__value {
					font-size: 36px;
					line-height: 1.3; } } }
		&_email {
			.item-contact {
				&__title {
					margin-bottom: 5px; } } }
		&__title {
			font-size: 24px;
			margin-bottom: 10px; }
		&__value {
			font-size: 30px;
			line-height: 1.3; } }

	.item-participant {
		padding: 60px;
		&__col {
			flex: 0 0 100%;
			max-width: none;
			width: 100%; }
		&__img-wrap {
			display: block;
			height: auto;
			margin-bottom: 55px; }
		&__img {
			margin: 0 auto; }
		&__text-wrap, &__link {
			font-size: 22px; }
		&__text-wrap {
			font-weight: 300;
			line-height: 1.33; } } }

@media screen and (max-width: 768px) {
	// Общие стили и компоненты
	body {
		font-size: 16px; }

	.main-title {
		font-size: 45px; }

	.page-headline {
		margin: 20px 0 44px;
		&__icon {
			max-width: 80px;
			margin-right: 40px; } }

	.list {
		&-default {
			padding: 0 0 0 10px;
			li::before {
				top: 10px; } } }

	.control, .textarea {
		font-size: 18px; }
	// Шапка
	// Футер
	// Секции
	.section-showcase {
		padding: 20px 0 62px;
		.showcase {
			margin-bottom: 50px; } }

	.section-popular-articles {
		padding: 0 0 80px; }

	.section-articles {
		.categories {
			margin-bottom: 50px; } }

	.card-article {
		&__title {
			font-size: 26px; } }

	.section-content {
		&__inner {
			padding: 35px 0 90px; } }

	.content {
		&__body {
			line-height: 1.4;
			p {
				margin-bottom: 20px; } } }

	.orders {
		padding: 32px 24px 30px; }

	.order {
		padding: 35px 0;
		&__title {
			font-size: 18px;
			margin-right: 40px; }
		&__btns {
			flex-direction: column-reverse;
			margin: 0; }
		&__btn {
			margin: 0;
			&:not(:first-child) {
				margin-bottom: 34px; } } }

	.custom-order {
		&__header {
			padding: 35px 0 0; }
		&__title {
			font-size: 18px; }
		&__form {
			margin-top: 30px; } }

	.form-order {
		&__textarea {
			font-size: 18px; }
		&__btn {
			font-size: 18px; } }

	.form-cart {
		&__text {
			margin-bottom: 55px; } }

	.about {
		&__content {
			font-size: 20px; } }

	.message-about {
		&__text-wrap {
			font-size: 20px; }
		&__title {
			font-size: 30px; } }

	.section-services {
		&__inner {
			padding: 90px 0 0; } }

	.section-privacy {
		padding: 80px 0; }

	.page {
		.section-partners {
			padding: 90px 0; } }

	.cart {
		padding: 0 0 120px; }

	.contacts {
		&__item {
			&:not(:last-child) {
				margin-bottom: 40px; } } }

	.participants {
		&__item {
			&:not(:last-child) {
				margin-bottom: 44px; } } }

	.item-participant {
		padding: 60px 40px 40px;
		&__title {
			font-size: 30px; }
		&__text-wrap, &__link {
			font-size: 20px; }
		&__img {
			max-width: 90%;
			max-height: 121px; } } }

@media screen and (max-width: 576px) {
	// Общие стили и компоненты

	.main-title {
		font-size: 36px; }
	.section-title {
		font-size: 21px;
		letter-spacing: 0.25em; }
	.section-subtitle {
		font-size: 13px; }

	.page-headline {
		margin: 16px 0 44px;
		&__icon {
			max-width: 63px;
			margin-right: 25px; } }

	.checkbox {
		&__box {
			margin: 3px 10px 0 0; }
		&__text {
			font-size: 18px;
			line-height: 1.67; } }

	.list {
		&-accent {
			li {
				padding-left: 27px;
				font-size: 18px;
				&::before {
					top: 7px; } } } }

	.control, .textarea {
		font-size: 16px;
		padding: 20px 17px;
		line-height: 1.7; }

	// Шапка
	.header {
		&__logo {
			img {
				max-width: 180px; } } }
	// Футер
	.footer {
		&__logo {
			img {
				max-width: 180px; } } }
	// Секции
	.section-showcase {
		padding: 5px 0 62px;
		.showcase {
			margin-bottom: 43px; } }

	.showcase {
		&__title {
			display: block;
			word-break: break-word; }
		&__text-wrap {
			font-size: 15px; } }

	.card-service {
		&__front, &__back {
			padding: 30px 30px 23px; } }

	.section-articles {
		.categories {
			margin-bottom: 44px; } }

	.categories {
		margin-left: -8px;
		margin-right: -8px;
		&__link {
			margin: 0 8px; } }

	.card-article {
		&__title {
			font-size: 24px; }
		&__category, &__date {
			font-size: 10px; }
		&__category {
			margin-right: 13px; }
		&__meta {
			margin-bottom: 15px; }
		&__img-wrap {
			margin-bottom: 15px; } }

	.privacy {
		&__title {
			display: block;
			word-break: break-word;
			margin-bottom: 40px; }
		&__description {
			line-height: 1.33; } }

	.section-content {
		&__inner {
			border-top: none;
			padding: 0 0 90px; } }

	.message-content {
		padding: 26px 24px 38px 24px;
		&__title {
			font-size: 30px;
			margin-bottom: 25px; }
		&__separator {
			margin: 30px 0 22px; } }

	.order {
		padding: 31px 0;
		&__title {
			font-size: 16px;
			margin-right: 20px; } }

	.custom-order {
		&__header {
			padding: 31px 0 0; }
		&__title {
			font-size: 16px; }
		&__form {
			margin-top: 27px; } }

	.form-order {
		&__textarea {
			font-size: 16px;
			height: 291px; }
		&__btn-wrap {
			margin-left: -10px;
			margin-right: -10px; }
		&__btn {
			margin: 0 10px; } }

	.form-cart {
		&__title {
			margin-bottom: 46px; }
		&__text {
			margin-bottom: 50px; }
		&__btn {
			padding: 20px;
			width: 100%; } }

	.about {
		&__content {
			font-size: 18px; } }

	.message-about {
		&__text-wrap {
			font-size: 18px; } }

	.page {
		&_cart, &_about, &_articles, &_contacts, &_members {
			.page-headline {
				&__title {
					text-transform: uppercase; } } }
		&_content {
			.page-headline {
				margin: 16px 0 26px;
				&__title {
					font-size: 30px; } } } }

	.cart {
		padding: 0 0 100px; }

	.contacts {
		&__item {
			&:not(:last-child) {
				margin-bottom: 30px; } } }

	.item-participant {
		padding: 60px 24px 40px;
		&__text-wrap, &__link {
			font-size: 18px; } } }

@media screen and (max-width: 375px) {
	.btn {
		font-size: 20px; }

	.form-order {
		&__btn-wrap {
			display: block;
			margin: 0; }
		&__btn {
			flex: 0 0 100%;
			max-width: none;
			width: 100%;
			margin: 0;
			&:not(:last-child) {
				margin-bottom: 15px; } } } }

/*==========  Mobile First  ==========*/

@media screen and (min-width: 1200px) {
	// Общие стили и компоненты
	// Шапка
	// Футер
 }	// Секции

@media screen and (min-width: 992px) {
	// Общие стили и компоненты
	.slider {
		&__nav {
			&:hover {
				svg {
					fill: $accent; } } } }

	.pagination {
		&__link {
			&:hover {
				color: lighten($secondary, 10); } } }
	// Шапка
	.submenu {
		a {
			&:hover {
				color: $secondary;
				font-weight: bold; } } }
	// Футер
	// Секции
	.card-article {
		&_row {
			display: flex;
			.card-article {
				&__img-wrap {
					flex: 0 0 377px;
					max-width: 377px;
					padding-bottom: calc(377px / (397 / 255));
					margin: 0 40px 0 0; } } }
		&:hover {
			.card-article {
				&__img {
					transform: scale(1.1); } } } }

	.categories {
		&__link {
			&:hover {
				&::before {
					transform: scaleX(1); } } } } }

@media screen and (min-width: 768px) {
	// Общие стили и компоненты
	// Шапка
	// Футер
 }	// Секции

@media screen and (min-width: 576px) {
	// Общие стили и компоненты
	// Шапка
	// Футер
 }	// Секции
